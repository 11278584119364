import { lightPalette } from "./pallete";
import typography from "./typography";

export const themeLight = {
  palette: lightPalette,
  typography,
};

export const themeDark = {
  palette: lightPalette,
  typography,
};
