import css from "@emotion/css";
export const globalStyles = css`
  @font-face {
    font-family: "Roboto";
    src: local("Roboto Medium"), local("Roboto-Medium"),
      url("/static/fonts/subset-Roboto-Medium.woff2") format("woff2"),
      url("/static/fonts/subset-Roboto-Medium.woff") format("woff");
    font-weight: 500;
    font-style: normal;
  }

  @font-face {
    font-family: "Roboto";
    src: local("Roboto Italic"), local("Roboto-Italic"),
      url("/static/fonts/subset-Roboto-Italic.woff2") format("woff2"),
      url("/static/fonts/subset-Roboto-Italic.woff") format("woff");
    font-weight: normal;
    font-style: italic;
  }

  @font-face {
    font-family: "Roboto";
    src: local("Roboto Thin"), local("Roboto-Thin"),
      url("/static/fonts/subset-Roboto-Thin.woff2") format("woff2"),
      url("/static/fonts/subset-Roboto-Thin.woff") format("woff");
    font-weight: 100;
    font-style: normal;
  }

  @font-face {
    font-family: "Roboto";
    src: local("Roboto Black Italic"), local("Roboto-BlackItalic"),
      url("/static/fonts/subset-Roboto-BlackItalic.woff2") format("woff2"),
      url("/static/fonts/subset-Roboto-BlackItalic.woff") format("woff");
    font-weight: 900;
    font-style: italic;
  }

  @font-face {
    font-family: "Roboto";
    src: local("Roboto Black"), local("Roboto-Black"),
      url("/static/fonts/subset-Roboto-Black.woff2") format("woff2"),
      url("/static/fonts/subset-Roboto-Black.woff") format("woff");
    font-weight: 900;
    font-style: normal;
  }

  @font-face {
    font-family: "Roboto";
    src: local("Roboto Bold"), local("Roboto-Bold"),
      url("/static/fonts/subset-Roboto-Bold.woff2") format("woff2"),
      url("/static/fonts/subset-Roboto-Bold.woff") format("woff");
    font-weight: bold;
    font-style: normal;
  }

  @font-face {
    font-family: "Roboto";
    src: local("Roboto Medium Italic"), local("Roboto-MediumItalic"),
      url("/static/fonts/subset-Roboto-MediumItalic.woff2") format("woff2"),
      url("/static/fonts/subset-Roboto-MediumItalic.woff") format("woff");
    font-weight: 500;
    font-style: italic;
  }

  @font-face {
    font-family: "Roboto";
    src: local("Roboto Light"), local("Roboto-Light"),
      url("/static/fonts/subset-Roboto-Light.woff2") format("woff2"),
      url("/static/fonts/subset-Roboto-Light.woff") format("woff");
    font-weight: 300;
    font-style: normal;
  }

  @font-face {
    font-family: "Roboto";
    src: local("Roboto Bold Italic"), local("Roboto-BoldItalic"),
      url("/static/fonts/subset-Roboto-BoldItalic.woff2") format("woff2"),
      url("/static/fonts/subset-Roboto-BoldItalic.woff") format("woff");
    font-weight: bold;
    font-style: italic;
  }

  @font-face {
    font-family: "Roboto";
    src: local("Roboto Thin Italic"), local("Roboto-ThinItalic"),
      url("/static/fonts/subset-Roboto-ThinItalic.woff2") format("woff2"),
      url("/static/fonts/subset-Roboto-ThinItalic.woff") format("woff");
    font-weight: 100;
    font-style: italic;
  }

  @font-face {
    font-family: "Roboto";
    src: local("Roboto Light Italic"), local("Roboto-LightItalic"),
      url("/static/fonts/subset-Roboto-LightItalic.woff2") format("woff2"),
      url("/static/fonts/subset-Roboto-LightItalic.woff") format("woff");
    font-weight: 300;
    font-style: italic;
  }

  @font-face {
    font-family: "Roboto";
    src: local("Roboto"), local("Roboto-Regular"),
      url("/static/fonts/subset-Roboto-Regular.woff2") format("woff2"),
      url("/static/fonts/subset-Roboto-Regular.woff") format("woff");
    font-weight: normal;
    font-style: normal;
  }

  @font-face {
    font-family: "Alegreya";
    src: local("Alegreya Regular"), local("Alegreya-Regular"),
      url("/static/fonts/subset-Alegreya-Regular.woff2") format("woff2"),
      url("static/fonts/subset-Alegreya-Regular.woff") format("woff");
    font-weight: normal;
    font-style: normal;
  }

  @font-face {
    font-family: "Alegreya";
    src: local("Alegreya Medium"), local("Alegreya-Medium"),
      url("/static/fonts/subset-Alegreya-Medium.woff2") format("woff2"),
      url("/static/fonts/subset-Alegreya-Medium.woff") format("woff");
    font-weight: 500;
    font-style: normal;
  }

  @font-face {
    font-family: "Noto Sans";
    src: local("Noto Sans Bold"), local("NotoSans-Bold"),
      url("/static/fonts/subset-NotoSans-Bold.woff2") format("woff2"),
      url("/static/fonts/subset-NotoSans-Bold.woff") format("woff");
    font-weight: bold;
    font-style: normal;
  }

  @font-face {
    font-family: "Noto Sans";
    src: local("Noto Sans Light"), local("NotoSans-Light"),
      url("/static/fonts/subset-NotoSans-Light.woff2") format("woff2"),
      url("/static/fonts/subset-NotoSans-Light.woff") format("woff");
    font-weight: 300;
    font-style: normal;
  }

  @font-face {
    font-family: "Alegreya";
    src: local("Alegreya Bold"), local("Alegreya-Bold"),
      url("/static/fonts/subset-Alegreya-Bold.woff2") format("woff2"),
      url("/static/fonts/subset-Alegreya-Bold.woff") format("woff");
    font-weight: bold;
    font-style: normal;
  }

  @font-face {
    font-family: "Noto Sans";
    src: local("Noto Sans Bold"), local("NotoSans-Bold"),
      url("/static/fonts/subset-NotoSans-Bold.woff2") format("woff2"),
      url("/static/fonts/subset-NotoSans-Bold.woff") format("woff");
    font-weight: bold;
    font-style: normal;
  }

  @font-face {
    font-family: "Noto Sans";
    src: local("Noto Sans"), local("NotoSans"),
      url("/static/fonts/subset-NotoSans.woff2") format("woff2"),
      url("/static/fonts/subset-NotoSans.woff") format("woff");
    font-weight: normal;
    font-style: normal;
  }

  *:not(input):not(textarea) {
    box-sizing: border-box;
    -webkit-touch-callout: none;
    -webkit-user-select: none; // locks fields on Safari
    -khtml-user-select: none; // locks fields on Safari
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
  }

  html,
  body,
  div,
  span,
  applet,
  object,
  iframe,
  blockquote,
  pre,
  a,
  abbr,
  acronym,
  address,
  big,
  cite,
  code,
  del,
  dfn,
  em,
  img,
  ins,
  kbd,
  q,
  s,
  samp,
  small,
  strike,
  strong,
  sub,
  sup,
  tt,
  var,
  b,
  u,
  i,
  center,
  dl,
  dt,
  dd,
  ol,
  ul,
  li,
  fieldset,
  form,
  label,
  legend,
  table,
  caption,
  tbody,
  tfoot,
  thead,
  tr,
  th,
  td,
  article,
  aside,
  canvas,
  details,
  embed,
  figure,
  figcaption,
  footer,
  header,
  hgroup,
  menu,
  nav,
  output,
  ruby,
  section,
  summary,
  time,
  mark,
  audio,
  video {
    margin: 0;
    padding: 0;
    border: 0;
    font: inherit;
    vertical-align: baseline;
  }

  input,
  textarea,
  button,
  select,
  div,
  a {
    -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
  }

  body {
    font-size: 16px;
    touch-action: pan-y;
    text-rendering: optimizeLegibility;
    text-rendering: optimizeLegibility;
    line-height: 1;
    font-family: "Noto Sans", sans-serif;
    -webkit-font-smoothing: antialiased;
  }

  a {
    cursor: pointer;
    text-decoration: none;
    color: inherit;
  }

  a:visited {
    color: inherit;
  }

  ol,
  ul {
    list-style: none;
  }

  blockquote,
  q {
    quotes: none;
  }

  blockquote:before,
  blockquote:after,
  q:before,
  q:after {
    content: "";
    content: none;
  }

  table {
    border-collapse: collapse;
    border-spacing: 0;
  }
`;
