export default {
  fonts: {
    body: "Noto Sans, sans-serif",
    heading: "Roboto, sans-serif",
  },
  text: {},
  fontSizes: {
    xs: "0.75rem", // 12px
    sm: "0.875rem", // 14px
    md: "1rem", // 16px
    lg: "1.1875rem", // 19px
    xl: "1.9375rem", // 31px
    "2xl": "3.125rem", // 50px
    "3xl": "5rem", // 80px
  },
  fontWeights: {
    hairline: 100,
    thin: 200,
    light: 300,
    normal: 400,
    medium: 500,
    semibold: 600,
    bold: 700,
    extrabold: 800,
    black: 900,
  },
  lineHeights: {
    normal: "normal",
    none: "1",
    shorter: "1.125",
    short: "1.2",
    base: "1",
    tall: "1.473",
  },
};
