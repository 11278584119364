import React, { useEffect, useState } from "react";
import {
  store,
  AppState,
  reHydrateBrowserStore,
  bootstrap,
  SystemState,
} from "@store/index";
import { Provider, useDispatch } from "react-redux";
import { Store } from "@reduxjs/toolkit";
import { Global } from "@emotion/core";
import { ThemeProvider } from "emotion-theming";

import MainLayout from "@components/MainLayout";
import { globalStyles } from "globalStyles";
import { themeLight, themeDark } from "theme/theme";
import IRouter from "next/dist/next-server/lib/router/router";
import Router from "next/router";

import * as Sentry from "@sentry/node";
import { RewriteFrames } from "@sentry/integrations";
import { initGA, logPageView } from "@utils/analytics";

if (process.env.NEXT_PUBLIC_SENTRY_DSN) {
  const distDir = `.next`;
  Sentry.init({
    // enabled: process.env.NODE_ENV === "production",
    enabled: true,
    integrations: [
      new RewriteFrames({
        iteratee: (frame) => {
          // @ts-ignore
          frame.filename = frame.filename.replace(distDir, "app:///_next");
          return frame;
        },
      }),
    ],
    dsn: process.env.NEXT_PUBLIC_SENTRY_DSN,
  });
}

export const LangContext = React.createContext("sr");

const Startup = ({ children }) => {
  const dispatch = useDispatch();
  const [init, setInit] = useState<SystemState>();

  const asyncPrepare = async () => {
    const data = await reHydrateBrowserStore();
    if (data && JSON.stringify(data) !== JSON.stringify(init)) {
      setInit(data);
    }
  };

  useEffect(() => {
    asyncPrepare();
    if (init) {
      dispatch(bootstrap(init));
    }
  }, [init]);

  return <>{children}</>;
};

const MyApp = ({ Component, pageProps, router, err }) => {
  const getLayout =
    Component.getLayout || ((page) => <MainLayout children={page} />);

  const [isDark, setIsDark] = useState(false);

  const handleRouteChange = (url) => {
    // @ts-ignore
    // URL is handled by logPageView check
    // TODO: should handle consent before
    logPageView();
  };

  useEffect(() => {
    // @ts-ignore
    if (!window.GA_INITIALIZED) {
      initGA();
      //@ts-ignore
      window.GA_INITIALIZED = true;
      Router.events.on("routeChangeComplete", handleRouteChange);
    }
  }, []);

  const lang = getLang(router);

  return (
    <LangContext.Provider value={lang}>
      <Provider store={store as Store<AppState>}>
        <Global styles={globalStyles} />
        <ThemeProvider theme={isDark ? themeDark : themeLight}>
          <Startup>
            {getLayout(
              <Component
                {...pageProps}
                setIsDark={setIsDark}
                isDark={isDark}
                err={err}
              />
            )}
          </Startup>
        </ThemeProvider>
      </Provider>
    </LangContext.Provider>
  );
};

function isValidLang(langFromPath) {
  return true;
}

const defaultLang = "sr";

function getLang(router: IRouter) {
  const langFromQuery = Array.isArray(router.query.lang)
    ? router.query.lang[0]
    : router.query.lang;
  if (langFromQuery && isValidLang(langFromQuery)) {
    return langFromQuery;
  }

  const langFromPath = router.asPath.split("/")[1];
  if (langFromPath && isValidLang(langFromPath)) {
    return langFromPath;
  }

  return defaultLang;
}

export default MyApp;
