const primaryColors = {
  dark: "#90001C",
  main: "#B70024",
  light: "#EB002D",
  white: "#fff",
  black: "#333",
  gray: "#F3F3F3",
  green: "#1A3024",
  facebook: "#3A559F",
  twitter: "#50ABF1",
  instagram: "#C536A4",
};

export const lightPalette = {
  type: "light",
  colors: primaryColors,
  text: {
    primary: primaryColors.black,
    secondary: primaryColors.white,
    link: primaryColors.light,
  },
  button: {
    buttonText: primaryColors.white,
    buttonTextHover: primaryColors.white,
    buttonBorder: primaryColors.light,
    buttonBg: primaryColors.light,
    buttonBgHover: primaryColors.main,
  },
  background: primaryColors.white,
  darkBackground: primaryColors.green,
};
